import { gql } from '@apollo/client';

const filter = `
  _or: [
    {name: {_ilike: $filterSearchString}},
    {organizer: {name: {_ilike: $filterSearchString}}},

  ],
  _and: [
    {_and: [{start_date: {_gte: $filterFrom}}, {start_date: {_lt: $filterTo}}]},
    {_and: [{end_date: {_gt: $filterFrom}}]}
  ]
`;

export const getEvents = (user?: string, filterable?: boolean) => {
  return gql`
    query getEvents($filterSearchString: String, $filterTo: timestamptz, $filterFrom: timestamptz) {
      events(limit: 50, order_by: { end_date: desc }, where: {
       ${filterable ? filter : ''}
       ${filterable && !user ? `status: { _eq: "published" }` : ''}
       ${user ? `organizer: { user_id: { _eq: "${user}" } }` : ''}
      }) {
        id
        name
        description
        tos
        image: image_url
        start_date
        event_type
        end_date
        status
        category
        address
        social
        venue {
          id
          name
          address_line1
          address_line2
          status
        }
        venues{
          venue {
            id
            name
            address_line1
            address_line2
            status
          }
        }
        organizer {
          id
          name
        }
        ticket_types(order_by: { created_at: desc }) {
          id
          name
          price
          currency
          status
          sales_start
          sales_end
          description
          start_date
          end_date
          type
        }
      }
    }
  `;
};


// checkins: check_ins_aggregate {
//   aggregate {
//     count
//     sum {
//       id
//     }
//   }
// }
export const getEvent = (id, forOrganizer = false) => {
  return gql`
      query {
        event:events_by_pk(id: ${id}) {
        id
        name
        description
        tos
        image: image_url
        start_date
        end_date
        status
        category
        event_type
        social
        ticket_types(order_by: { created_at: desc, sort: asc }, where: {ticket_group_id: {_is_null: true}}) {
          id
            currency
            description
            end_date
            name
            price
            questions
            sales_end
            sales_start
            start_date
            status
            type
        }
        ticket_groups(order_by: { name: asc }){
          id
          name
          description
          ticket_types(order_by: { price: asc }) {
            id
            name
            price
            sales_start
            sales_end
            start_date
            end_date
            currency
            type
            description
            questions
          }
        }
        address
        venues {
          venue {
            id
            name
            address_line1
            address_line2
            status
            image_url
          }
        }
        organizer {
          id
          name
          user_id
        }
        ${forOrganizer === true
      ? `
          orders: orders(order_by: { first_name: desc, last_name: desc }, where: { status: { _eq: "paid" } }) {
            id
            uid
            created_at
            first_name
            last_name
            email
            total
            phone
            items
            status
            venue
            code
            answers
          }
          attendees: Tickets(order_by: { first_name: desc, last_name: desc }, where: { status: { _eq: "paid" } }) {
            id
            order_id
            day
            name
            created_at
            first_name
            last_name
            email
            phone
            status
            venue
            code
          }
          ticket_distribution(where: { event_id: {_eq: ${id}} }) {
            event_id
            ticket_id
            name
            count: ticket_count
            amount: ticket_price
          }
          orders_aggregate(where: {status: {_eq: "paid"}}) {
            aggregate {
              count
              sum {
                total
              }
            }
          }
          `
      : ''
    }
      }
    }
  `;
};

export const getOrder = (id, forOrganizer = false) => {
  return gql`
    query {
      order:orders_by_pk(id: ${id}) {
        id
        uid
        first_name
        last_name
        items
        total
        day
        days
        status
        event {
          id
          name
          description
          image: image_url
          start_date
          end_date
          status
          category
          social
          event_type
          ticket_types(order_by: { created_at: desc }) {
            id
            name
            price
            sales_start
            sales_end
            start_date
            end_date
            type
            description
          }
          venue {
            id
            name
            address_line1
            address_line2
            status
            image_url
          }
          venues{
            venue {
              id
              name
              address_line1
              address_line2
              status
              image_url
            }
          }
          address
          organizer {
            id
            name
            user_id
          }
          ${forOrganizer === true
      ? `
          attendees: Tickets(order_by: { first_name: desc, last_name: desc }, where: { status: { _eq: "paid" } }) {
            id
            created_at
            first_name
            last_name
            email
            phone
            status
            code
            venue
            event{
              address
            }
          }
          ticket_distribution(where: { event_id: {_eq: ${id}} }) {
            event_id
            ticket_id
            name
            count: ticket_count
            amount: ticket_price
          }
          orders_aggregate(where: {status: {_eq: "paid"}}) {
            aggregate {
              count
              sum {
                total
              }
            }
          }
          `
      : ''
    }
        }
      }
    }
  `;
};

export const getTickets = () => {
  return {
    query: `query getTickets{
      tickets(limit:20) {
        id
        name
    }`
  };
};

export const createCheckIn = (event, ticket) => {
  return {
    query: `mutation InsertCheckIn {
      insert_check_ins(
        objects:[
          {
            event_id: "${event}"
            ticket_id: ${ticket}
          }
        ]
      ){
        returning{
          id
          event{
            id
            name
          }
          ticket{
            id
          }
        }
      }
    }`
  };
};

export const getUser = (id) => {
  return {
    query: `query GetUser{
      user:users_by_pk(id:"${id}") {
        id
        name
        avatar
      }
    }`
  };
};

export const getTicket = (code) => {
  return gql`
    query {
      ticket:Tickets(where: {code: {_eq: "${code}"}}) {
        id
        name
        stream
        venue
        event {
          id
          name
          description
          event_type
          image: image_url
          start_date
          end_date
          status
          category
          social
          organizer {
            id
            name
            user_id
          }
        }
      }
    }
  `;
};

export const flutterWavePaymentLink = gql`
  mutation FlutterwavePaymentLink($uid: String!) {
    flutterwave(order: { uid: $uid }) {
      link
    }
  }
`;
