import React from 'react';
import { Router, useParams } from '@reach/router';
import { Event as EventLayout } from 'tutadoo-design-system/src/components/pages/Event';
import { habitat } from 'tutadoo-design-system/src/themes/habitat';
import { Grommet } from 'grommet';
import { useQuery } from '@apollo/client';
import { getEvent } from 'services/hasura';
import { useAuth0 } from '@auth0/auth0-react';

const EventPage = () => {
  const params = useParams();
  const { isAuthenticated } = useAuth0();
  const resp: { data: namespace.Data; loading: boolean } = useQuery(getEvent(params.id));
  return (
    <EventLayout
      basePath=""
      authenticated={isAuthenticated}
      loading={resp.loading}
      event={resp.data?.event}
      getTicketsURL={`/booking/checkout/?id=${params.id}`}
    />
  );
};

const Event = () => {
  return (
    <Grommet theme={habitat} background="transparent">
      <Router basepath="/event">
        <EventPage path="/:name/:id" />
      </Router>
    </Grommet>
  );
};

export default Event;
